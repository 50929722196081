import { render, staticRenderFns } from "./ListAllBooksLoading.vue?vue&type=template&id=3c0947fa&scoped=true&"
import script from "./ListAllBooksLoading.vue?vue&type=script&lang=ts&"
export * from "./ListAllBooksLoading.vue?vue&type=script&lang=ts&"
import style0 from "./ListAllBooksLoading.scss?vue&type=style&index=0&id=3c0947fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0947fa",
  null
  
)

export default component.exports