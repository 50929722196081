











































import { 
  Component, Mixins, Prop, Watch
} from 'vue-property-decorator';

import Books from '@/components/Book/index.vue';
import ExSlick from '@/components/Slick/index.vue';

import ListAllBooksLoading from './ListAllBooksLoading.vue';

import BookService from '@/services/Book/BookService';

import generalBooks from '@/mixins/generalBooks';

import { Book as IBook } from '@/globalInterfaces/Book';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    ExSlick,
    Books,
    ListAllBooksLoading
  }
})
export default class ListAllBooks extends Mixins(generalBooks) {
  @Prop({ default: () => [] }) removeBooks!: Array<number>;
  @Prop() currentBookDegree!: number;
  @Prop() loading!: boolean;

  private allBooks: Array<IBook> = [];
  private quantityLoading = 4;

  private BookService = new BookService();

  @Watch('removeBooks', {
    immediate: true,
    deep: true
  })
  async getBooks() {
    if (this.loading) return;

    try {
      const getAllbooks = await this.BookService.books(
        [this.currentBookDegree],
        this.$store.getters.profile?.institution?.id
      );

      this.allBooks = this.setBooks(getAllbooks);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar outros cadernos.',
        status: 'error'
      });

      console.error(error);
    }
  }

  setBooks(books: Array<IBook>) {
    return books
      .map((book: IBook) => ({
        ...book,
        id: book.idBooks,
        link: `/caderno/${book.slug}`,
        type: 'books',
        disable: false
      }))
      .filter((book: IBook) => !this.removeBooks.includes(book.idBooks));
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ListAllBooks',
          local: 'Outros cadernos',
          page: this.$route.name
        }
      }
    });
  }
}
